import * as zoid from '@krakenjs/zoid/dist/zoid';

function getValueAndSubsequentParams(urlString) {
  let params;
  if (urlString instanceof URL) {
    params = urlString.searchParams;
  } else if (typeof urlString === 'string' && urlString.startsWith('http')) {
    try {
      params = new URL(urlString).searchParams;
    } catch (error) {
      console.error('Invalid URL:', urlString);
      return null;
    }
  } else if (typeof urlString === 'string') {
    // handle components props value
    return urlString.startsWith('/') ? urlString.slice(1) : urlString;
  } else {
    console.error('Unsupported input type:', urlString);
    return null;
  }

  const paramValue = params.get('cm_page') || params.get('page');
  if (paramValue) {
    params.delete('cm_page');
    params.delete('page');
  }

  const remainingParams = params.toString();
  const strippedValue =
    paramValue && paramValue.startsWith('/') ? paramValue.slice(1) : paramValue;
  return strippedValue
    ? strippedValue + (remainingParams ? '&' + remainingParams : '')
    : remainingParams;
}

export let PageComponent = zoid.create({
  tag: 'crediblemind-embed', // This has to be unique per js loaded on the page
  dimensions: ({ props }) => {
    let hasDynamicHeight = false;
    if (props.hasOwnProperty('setIframeHeight')) {
      // props has the setIframeHeight property
      hasDynamicHeight = true;
    }

    return {
      width: '100%',
      height: hasDynamicHeight ? '200px' : '1000px',
    };
  },
  autoResize: {
    width: false,
    height: false,
  },
  attributes: ({ props }) => {
    let hasDynamicHeight = false;
    if (props.hasOwnProperty('setIframeHeight')) {
      // props has the setIframeHeight property
      hasDynamicHeight = true;
    }

    return {
      iframe: {
        allowfullscreen: 'allowfullscreen',
        scrolling: hasDynamicHeight ? 'no' : 'yes',
        frameborder: '0',
      },
    };
  },
  url: ({ props }) => {
    const search = props.parent
      ? getValueAndSubsequentParams(
          typeof props.parent === 'function'
            ? props.parent()
            : props.parent?.href,
        )
      : '';

    const components = props?.components.length
      ? getValueAndSubsequentParams(props.components[0])
      : [];
    const splitSearch = search ? search.split('=') : [];
    const splitComponent = components.length ? components.split('=') : [];
    const hasAddedUrlParam = search
      ? splitSearch.length > 1
      : components
      ? splitComponent.length > 1
      : false;
    const brandParamSymbol = hasAddedUrlParam ? '&' : '?';
    const route = search ? search : components ? components : '';

    const finalRoute = route || '';

    let baseUrl = '';
    let queryParts = [];
    let finalPageRoute;

    if (finalRoute.includes('?')) {
      finalPageRoute = finalRoute;
    } else {
      if (typeof finalRoute === 'string') {
        [baseUrl, ...queryParts] = finalRoute.split('&');
      } else if (Array.isArray(finalRoute) && finalRoute.length > 0) {
        [baseUrl, ...queryParts] = finalRoute[0].split('&');
      }

      // Convert the query parts into an object
      const params = queryParts.reduce((acc, param) => {
        const [key, value] = param.split('=');
        acc[key] = value;
        return acc;
      }, {});

      // Convert the params object back into a query string in the correct format
      const queryString = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      finalPageRoute = `${baseUrl}${queryString ? `?${queryString}` : ''}`;
    }

    const demoURL = `https://development.crediblemind.com/${finalPageRoute}`;
    const hasBrandParam = demoURL.includes('brand=');
    const [client] = props.client.split('-');
    return {
      dev: `http://localhost:3000/${finalPageRoute}`,
      demo: hasBrandParam
        ? demoURL
        : `${demoURL}${brandParamSymbol}brand=${client}`,
      production: `https://${client}.crediblemind.com/${finalPageRoute}`,
    }[props.env];
  },
  props: {
    env: {
      type: 'string',
      default: () => 'production',
    },
    embed: {
      type: 'boolean',
      default: () => true,
    },
    client: {
      type: 'string',
    },
    /**
     * bare = no footer, header and hero landing
     * full = whole page app (default)
     */
    type: {
      type: 'string',
      default: () => 'full',
    },
    /**
     * new_tab = all links redirect to new tab
     * in_app = links within iframe (default)
     */
    redirect: {
      type: 'string',
      default: () => 'in_app',
    },
    components: {
      type: 'array',
      default: () => [],
      required: false,
    },
    styles: {
      type: 'object',
      required: false,
    },
    controls: {
      type: 'object',
      required: false,
    },
    parentUrl: {
      type: 'string',
      default: () => {
        const { origin, pathname } = window.location;
        return `${origin}${pathname}`;
      },
    },
    subBrand: {
      type: 'string',
      default: ({ props }) => {
        const [, subBrand = ''] = props?.client.split('-');
        return subBrand;
      },
    },
  },
});
